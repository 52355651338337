//@ts-ignore
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';

type Props = {
  onDone: (data: any) => void;
};

type FormValues = {
  email: string;
  password: string;
};

export const B2BLoginForm: React.FC<Props> = ({ onDone }) => {
  const formSchema = Yup.object().shape({
    email: Yup.string().email().required(),
    password: Yup.string().required(),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({ resolver: yupResolver(formSchema) });

  const onSubmit = (data: FormValues) => {
    onDone(data);
  };

  return (
    <div className="auth">
      <h4 className="auth__title">Login</h4>
      <div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="form__field">
            <label>Email</label>
            <div>
              <input
                className="form__input"
                type="email"
                {...register('email')}
                placeholder="Email"
                autoComplete="email"
              />
            </div>
            {errors.email && <div className="form__error">{errors.email.message}</div>}
          </div>

          <div className="form__field">
            <label>Password</label>
            <div>
              <input
                className="form__input"
                type="password"
                {...register('password')}
                placeholder="Password"
                autoComplete="off"
              />
            </div>
            {errors.password && <div className="form__error">{errors.password.message}</div>}
          </div>
          <div className="form__field">
            <button className="button--primary" type="submit">
              Login
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};
